.gridjs-head {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 1px; }
  .gridjs-head::after {
    content: '';
    display: block;
    clear: both; }

.gridjs-head:empty {
  padding: 0;
  border: none; }
