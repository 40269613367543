.gridjs-wrapper {
  position: relative;
  z-index: 1;
  overflow: auto;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  border-radius: 8px 8px 0 0;
  display: block;
  border-top-width: 1px;
  border-color: #e5e7eb; }

.gridjs-wrapper:nth-last-of-type(2) {
  border-radius: 8px;
  border-bottom-width: 1px; }
