input.gridjs-input {
  outline: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 5px;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 1.45;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input.gridjs-input:focus {
    box-shadow: 0 0 0 3px rgba(149, 189, 243, 0.5);
    border-color: #9bc2f7; }
